import React from "react";
import Blink from "react-blink-text";

import Container from "../shared/UIelements/Container";

const Services = () => {
  return (
    <Container title="Leistungen">
      <div className="logoFirstText">Zu Hause in den besten Händen!</div>
      <div className="logoFirstTextRight">Gemeinsam, statt einsam!</div>
      <br />
      <br />
      <fieldset>
        <legend className="legendService">Grundpflege (SGB XI)</legend>
        <div className="sideTitle">
          <ul className="serviceListLeft">
            <li>Körperpflege</li>
            <li>Hilfe beim Toilettengang</li>
            <li>An- und Auskleiden</li>
            <li>Einnahme von Mahlzeiten</li>
            <li>Bewegungshilfe</li>
            <li>Förderung von Eigenständigkeit und Kommunikation</li>
            <li>Lagern in die richtige Sitz- und Liegepositionen</li>
          </ul>
        </div>
        <div className="sideText">
          Wir wissen genau, was den Alltag für Patienten und Angehörige leichter
          macht. Jeder Mensch kann pflegedürftig werden. Egal, ob für einen
          kurzen oder langen Zeitraum, ob durch einen Unfall oder des Alters
          wegen, wir helfen unseren Patienten im Rahmen einer Grundpflege bei
          allen körperlichen Belangen. Die Grundpflege umfasst somit die
          Unterstützung unserer Patienten bei der Körperpflege, beim
          Toilettengang, beim An- und Auskleiden sowie bei der Einnahme von
          Mahlzeiten.
        </div>
      </fieldset>
      <br />
      <br />

      <fieldset>
        <legend className="legendService">Behandlungspflege (SGB V)</legend>
        <div className="sideText">
          Wir pflegen Sie zu Hause! Unsere ausgebildeten Pflegefachkräfte,
          führen umfassende medizinische Leistungen aus. Nicht nur im
          Krankenhaus können Patienten medizinisch versorgt werden. Auch in den
          eigenen vier Wänden ist es möglich.
          <br />
          Wir überwachen unsere pflegebedürftigen Patienten und bieten beste
          medizinische Versorgung. Darunter fallen Tätigkeiten wie
          Medikamentengabe, Injektionen, Wundversorgung, Verbandwechsel,
          Blutdruck- und Blutzuckermessung, Insulingabe und vieles mehr.
        </div>
        <div className="sideTitle">
          <ul className="serviceListRight">
            <li>Blutdruck- und Blutzuckermessung</li>
            <li>Vorbereiten und Verabreichen von Medikamenten</li>
            <li>Injektionen</li>
            <li>Wundversorgung und Verbandswechsel</li>
            <li>Kompressionsstrümpfe an- und ausziehen</li>
            <li>Kompressionsverbände</li>
            <li>Dekubitusbehandlung</li>
            <li>Portversorgung</li>
            <li>Absaugen der oberen Luftwege durch Mund und Nase</li>
            <li>Inhalation</li>
            <li>Stomaversorgung</li>
            <li>Versorgung von suprapubischen Kathetern und PEG</li>
            <li>Katheterwechsel, Blasenspülung</li>
            <li>Einläufe</li>
            <li>Peritonealdialyse/Bauchfelldialyse</li>
          </ul>
        </div>
      </fieldset>
      <br />
      <br />

      <fieldset>
        <legend className="legendService">Häusliche Betreuung</legend>
        <div className="sideTitle">
          <ul className="serviceListLeft">
            <li>Freizeitbegleitung</li>
            <li>Bewegungsangebote</li>
            <li>Gemeinsamer Zeitvertreib</li>
            <li>Begleitung bei Arztbesuchen</li>
          </ul>
        </div>
        <div className="sideText">
          Wir leisten Ihnen Gesellschaft! Wir bieten eine liebevolle Betreuung
          und eine Vielzahl von Unterhaltungsmöglichkeiten.
          <br />
          Oft fühlen sich Menschen einsam, sobald ihre körperliche Kraft
          nachlässt. Gerade im Alter ist es wichtig, weiter an Lebensfreude zu
          gewinnen, indem geistige Elastizität und Beweglichkeit gefördert
          werden. Hierzu zählen beispielsweise Spaziergänge, Spiele, Vorlesen,
          Unterhalten, Begleitung bei Arztbesuchen und sonstige
          Alltagsbegleitung (z.B. Friseur, Fußpflege).
        </div>
      </fieldset>
      <br />
      <br />

      <fieldset>
        <legend className="legendService">Hauswirtschaftliche Hilfen</legend>
        <div className="sideText">
          Reinigung Rundum-Wohlfühleffekt! Denn nicht nur die körperliche Pflege
          ist ein wesentlicher Bestandteil für einen Rundum-Wohlfühleffekt, auch
          der Erhalt der häuslichen Umgebung gehört unweigerlich mit dazu.
          <br />
          Deshalb beinhalten unsere hauswirtschaftlichen Dienste Besorgungen
          (z.B. Einkäufe von Lebensmitteln, Abholen von Rezepten, Medikamenten,
          Verordnungen und Hilfsmitteln), das Kochen und Zubereiten von
          Mahlzeiten, das Aufräumen und Spülen, die Reinigung der Wohnung, das
          Waschen und Einräumen der Wäsche und vieles mehr.
        </div>
        <div className="sideTitle">
          <ul className="serviceListRight">
            <li>Einkaufen von Lebensmitteln</li>
            <li>Besorgungen aller Art</li>
            <li>Kochen und Zubereitungen von Mahlzeiten</li>
            <li>Reinigen der Wohnung</li>
            <li>Aufräumen und Spülen</li>
            <li>Waschen und Einräumen der Wäsche</li>
            <li>Müllentsorgung</li>
          </ul>
        </div>
      </fieldset>
      <br />
      <br />

      <fieldset>
        <legend className="legendService">
          Beratungsbesuche nach § 37 (3) SGB XI
        </legend>
        <div className="sideTitle">
          <ul className="serviceListLeft">
            <li>Beratung zu den Pflegegraden</li>
            <li>Klärung von Fragen zu Höherstufung</li>
            <li>Hilfsmittelbeschaffung</li>
            <li>Antragstellung</li>
          </ul>
        </div>
        <div className="sideText">
          Die Beratung und Information von Pflegebedürftigen und deren
          Angehörigen steht bei uns im Vordergrund. Sehr gern machen wir auch
          Beratungsbesuche im Rahmen des § 37.3 SGB XI oder beraten Sie zu
          möglichen Umgestaltungen im Wohnumfeld, damit die täglichen Handgriffe
          und Bewegungsabläufe besser auf die aktuelle Lebenssituation
          abgestimmt sind. Das Gleiche gilt für die Beratung zu Hilfsmitteln.
          <br />
          <br />
          Pflegegrad Beantragung
          <br />
          Gern unterstützen wir unsere Patienten bei den erforderlichen
          Anträgen. In Begleitung der Antragsstellung nehmen wir auch an den
          Begutachtungen des Medizinischen Dienstes der Krankenkassen (MDK) und
          des Sozialamtes teil, so dass wir Sie bei der Einstufung in die
          richtige Pflegestufe unterstützen.
        </div>
      </fieldset>
      <br />
      <br />

      <fieldset>
        <legend className="legendService">Verhinderungspflege</legend>
        <div className="sideText">
          Die Verhinderungspflege entlastet pflegende Angehörige, wenn diese mal
          eine Pause oder Auszeit für sich selbst nehmen möchten. Wir stellen
          eine professionelle Pflegefachkraft, die in dieser Zeit die Pflege
          übernimmt. Die Leistungen können für bis zu 6 Wochen im Jahr in
          Anspruch genommen werden, die Kosten werden von der Pflegeversicherung
          übernommen (bis zu € 1.612).
        </div>
        <div className="sideTitle">
          <ul className="serviceListRight">
            <li>Hilfe bei der Antragstellung</li>
            <li>Flexible Gestaltungsmöglichkeiten</li>
            <li>Informationsgespräch</li>
          </ul>
        </div>
      </fieldset>
      <br />
      <br />
      <br />
      <fieldset>
        <legend className="legendService">Individuelle Beratung für Sie</legend>
        <div class="textContainer">
          Pflege ist ein sehr persönliches Thema, deshalb möchten wir Sie
          persönlich und individuell beraten. Rufen Sie uns an oder kontaktieren
          Sie uns einfach und bequem über unsere{" "}
          <a
            href="mailto:info@premium-pflegedienst.de?subject=Informationen über Premium Pflegedienst"
            className="linkLine"
          >
            Email adresse
          </a>
          .
        </div>
      </fieldset>

      <br />
      <br />
      <br />
      <br />
      <div className="legendService">Schenken Sie Zeit!</div>
      <div class="textContainer">
        Zeit zu verschenken,
        <br />
        Oft kommt es vor, dass wir leider, durch den alltäglichen stress sehr
        wenig oder gar keine Zeit für unsere Angehörige haben.
        <br />
        Wir haben eine Lösung für Sie, wir haben Zeit zu verschenken. Sei es als
        kleines Dankeschön, als Geburtstagsgeschenk oder einfach so.
        <br />
        <br />
        <div className="infoService">
          <h3>Schenken Sie Zeit!</h3>
          <ul className="listServiceInfo">
            <li>zu Weihnachten</li>
            <li>zum Geburtstag</li>
            <li>zu Jubiläen</li>
            <li>als Dankeschön</li>
            <li>weil es DICH gibt!</li>
            <li>oder einfach so</li>
          </ul>
          <h3>Entspannen und Wohlfühlen</h3>
          Entspannungsbad mit Aromatherapie 60,- Euro
          <br />
          Handbad & Massage 25,- Euro
          <br />
          Fußbad & Massage 25,- Euro
          <br />
          Wellnessdusche 45,- Euro
          <br />
          <h3>Unterstützen und Begleiten</h3>
          Lebensmitteleinkauf 40,- Euro
          <br />
          Heimtierservice (Gassigehen, Aquarium reinigen etc. 30 Min.) 20,- Euro
          <br />
          Blumen gießen auf dem Friedhof, 5x 30,- Euro
          <br />
          Tägliche Zeitungsrunde (incl. Gedächtnistraining, 30 Min.) 20,- Euro
          <br />
          Unterhaltsame Stunde zu Hause (gemeinsames Kochen, Kaffee & Kuchen,
          Spiele-Nachmittag) 60,- Euro
          <br />
          Spaziergang, 60 Min. 40,- Euro
          <br />
          Trinkwecker (zusätzlicher Einsatz in der Sommerzeit, z.B. Getränke
          anreichen, die Wohnung lüften) 10,- Euro
          <br />
          <h3>Beraten und Planen</h3>
          Akten sortieren 60 Minuten (Dokumente aus den Bereichen Gesundheit,
          Vorsorge, Pflege) 50,- Euro
          <br />
          „Zukunftsplanung zum Lebensende“ 60 Min. 80,- Euro
          <br />
          Psychosoziale Betreuung 60 Min. 80,- Euro
        </div>
        <br/><br/>
        <div>
          Alle Angebote können in unserem Versorgungsgebiet (Raum
          Bietigheim-Bissingen, Tamm, Ingersheim und Freiberg am Neckar)
          eingelöst werden.<br/>
          Für den Fall, dass wir Ihnen sinnvolle Vorschläge
          machen konnten, können Sie Ihren ganz persönlich erstellten
          Geschenk-Gutschein unproblematisch unter
          <br/>Tel.<a href="tel:+4971422239345" className="linkLine">071422239345</a> oder per<br/>
          E-Mail <a href='mailto:info@premium-pflegedienst.de?subject=Informationen über Premium Pflegedienst' className="linkLine">info@premium-pflegedienst.de</a> anfordern.<br/>
          Übrigens, die Gutscheine können Sie auch erwerben, wenn Sie oder Ihre Angehörigen
          kein/e Patient/in unseres Pflegedienstes sind.
          <br/><br/>
          <h3>Machen wir es doch gemeinsam, statt einsam!</h3>
        </div>
      </div>
    </Container>
  );
};

export default Services;
