import React from "react";

import Container from "../shared/UIelements/Container";
import staff from "../images/staff/staff1.JPG";

// import positions from "../images/possitions.png";

const OpenPositions = () => {
  return (
    <Container title={"Stellenangebote"}>

      <p>Wir sind stets nach der Suche nach qualifiziertem Personal, sei es als
      Pflegehelfer/in, Pflegefachmann/Frau oder auch als Quereinsteiger/in, alle
      sind Willkommen in unser junges, offenes und motiviertes Team.</p>
      <div class="textContainer">Sei Dabei und versuche etwas in der Pflegewelt zu verändern.</div>
      <div>Bewerbungen unter:
        <a href='mailto:bewerbung@premium-pflegedienst.de?subject=Bewerbung oder Lebenslauf' className="linkLine">bewerbung@premium-pflegedienst.de</a>
    </div>
    <br/>
    <br/>
    <div><img src={staff} alt="Boss" className="openPossitionImage"></img></div>
    </Container>
  );
};

export default OpenPositions;
