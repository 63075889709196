import React from 'react';

import "./Container.css";

const Container= props => {
  return (
      <div className={"container"}>
        <div className={props.headerClass}>{props.title}</div>
        <div className="containerBody">{props.children}</div>
      </div>
    );
};

export default Container;