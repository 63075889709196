import Container from "../shared/UIelements/Container";

const Imprint = () => {
  return (
    <div>
      <Container title={"Impressum"}>
        <h3>INFORMATION:</h3>
        <p>Pflegedienst Premium</p>
        Moltkestraße 51, <br/>74321 Bietigheim-Bissingen<br/>
        Tel:    071422239345<br/>
        {/* Fax:    <br/> */}
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DEXXXXXXXXX <br/>
        E-Mail: <a href='mailto:info@premium-pflegedienst.de?subject=Informationen über Premium Pflegedienst' className="link">info@premium-pflegedienst.de</a><br/>
      </Container>
    </div>
  );
};

export default Imprint;
