import React from "react";
import { NavLink } from "react-router-dom";

import NavItems from "./NavItems";

const MenuDropDown = ({change}) => {
  return (
    <div className="menuMobile">
      <ul className="menuListMobile">
        {NavItems.map((n) => {
          return (
            <li className="listElementMobile" onClick={() => change()}>
              <NavLink className="menuLinkMobile" to={n.linkpath}>
                {n.linktext}
              </NavLink>
              <hr size="2" color="white"></hr>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuDropDown;
