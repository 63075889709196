import React from "react";
import Container from "../shared/UIelements/Container";

import phone from '../images/contact/phone.png';
import chat from '../images/contact/chat.png';
import insta from '../images/contact/insta.png';
import fb from '../images/contact/fb.png'
import pin from '../images/contact/pin.png';

import './Contact.css'

const Contact = () => {

    const openAddress = () => {
        window.open("https://www.google.com/maps/place/Pflegedienst+Premium/@48.9589992,9.1360386,17z/data=!4m15!1m8!3m7!1s0x4799d47f610be739:0x2def7cb6f752328e!2sMoltkestra%C3%9Fe+51,+74321+Bietigheim-Bissingen!3b1!8m2!3d48.9589957!4d9.1386135!16s%2Fg%2F11bw4n1y1b!3m5!1s0x4799d35af86df453:0xa41d7bf5a3563462!8m2!3d48.9589957!4d9.1386135!16s%2Fg%2F11svzb63j1?entry=ttu")
    }

  return (
    <div>
      <Container title={"Kontakt uns"}>
        <div class="textContainer">Wir freuen uns an unsere nächste Abenteuer. Kontaktieren Sie uns.</div>
        <br/><br/>
        <table>
          <tr className="imageBox">
            <td><img src={phone} alt="Phone" className="imageLine"></img></td>
            <td><label className="textLine">Telefonnummer: </label></td>
            <td><a href="tel:+4971422239345" className="linkLine">071422239345</a></td>
          </tr>
          <tr className="imageBox">
            <td><br/></td>
            <td><label className="textLine">Fax: </label></td>
            <td><a href="tel:+4971422239344" className="linkLine">071422239344</a></td>
          </tr>
          <tr className="imageBox">
            <td><img src={chat} alt="Email" className="imageLine"></img></td>
            <td><label className="textLine">Email: </label></td>
            <td><a href='mailto:info@premium-pflegedienst.de?subject=Informationen über Premium Pflegedienst' className="linkLine">info@premium-pflegedienst.de</a></td>
          </tr>
          <tr className="imageBox">
            <td><img src={insta} alt="Insta" className="imageLine"></img></td>
            <td><label className="textLine">Instagram: </label></td>
            <td><a href="https://www.instagram.com/premium_pflegedienst/" target="_blank" rel="noopener noreferrer" className="linkLine">@premium_pflegedienst</a></td>
          </tr>
          <tr className="imageBox">
            <td><img src={fb} alt="Facebook" className="imageLine"></img></td>
            <td><label className="textLine">Facebook: </label></td>
            <td><a href="https://www.facebook.com/profile.php?id=100093074696170" target="_blank" rel="noopener noreferrer" className="linkLine">Pflegedienst Premium</a></td>
          </tr>
          <tr className="imageBox">
            <td><img src={pin} alt="Pin" className="imageLine"></img></td>
            <td><label className="textLine">Adresse: </label></td>
            <td className="linkLine" onClick={openAddress}>Moltkestraße 51, 74321 Bietigheim-Bissingen</td>
          </tr>
        </table>
      </Container>
    </div>
  );
};

export default Contact;
