import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import "./App.css";

import Navigation from './shared/components/Navigation';
import Footer from "./shared/components/Footer";

import Overview from "./page/Overview";
import Contact from "./page/Contact";
import AboutUs from "./page/AboutUs";
import Services from "./page/Services";
import OpenPositions from "./page/OpenPositions";
import Gdpr from "./page/GDPR";
import Imprint from "./page/Imprint";

function App() {
  return (
    <div>
      <Router>
      <Navigation></Navigation>
      <Routes>
        <Route path="" element={<Overview/>}></Route>

        <Route path="overview" element={<Overview/>}></Route>
        <Route path="aboutUs" element={<AboutUs/>}/>
        <Route path="contact" element={<Contact/>}></Route>
        <Route path="service" element={<Services/>}/>
        <Route path="openPositions" element={<OpenPositions/>}/>
        <Route path="gdpr" element={<Gdpr/>}></Route>
        <Route path="imprint" element={<Imprint/>}/>
      </Routes>
      <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
