import Container from "../shared/UIelements/Container";

const Gdpr = () => {
  return (
    <div>
      <Container title={"Datenschutzerklärung"}>
        <div className="textContainer">
          Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
          Datenschutz hat einen besonders hohen Stellenwert für die
          Geschäftsleitung für folgenden Herausgeber dieser Website:
          <b> Pflegedienst Premium</b>. Eine Nutzung der Internetseiten der
          Pflegedienst Premium ist grundsätzlich ohne jede Angabe
          personenbezogener Daten möglich. Sofern eine betroffene Person
          besondere Services unseres Unternehmens über unsere Internetseite in
          Anspruch nehmen möchte, könnte jedoch eine Verarbeitung
          personenbezogener Daten erforderlich werden. Ist die Verarbeitung
          personenbezogener Daten erforderlich und besteht für eine solche
          Verarbeitung keine gesetzliche Grundlage, holen wir generell eine
          Einwilligung der betroffenen Person ein.
          <br />
          <br />
          Die Verarbeitung personenbezogener Daten, beispielsweise des Namens,
          der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
          Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
          und in Übereinstimmung mit den für die Pflegedienst Premium geltenden
          landesspezifischen Datenschutzbestimmungen. Mittels dieser
          Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über
          Art, Umfang und Zweck der von uns erhobenen, genutzten und
          verarbeiteten personenbezogenen Daten informieren. Ferner werden
          betroffene Personen mittels dieser Datenschutzerklärung über die ihnen
          zustehenden Rechte aufgeklärt.
          <br />
          <br />
          Die Pflegedienst Premium hat als für die Verarbeitung Verantwortlicher
          zahlreiche technische und organisatorische Maßnahmen umgesetzt, um
          einen möglichst lückenlosen Schutz der über diese Internetseite
          verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können
          internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken
          aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden
          kann. Aus diesem Grund steht es jeder betroffenen Person frei,
          personenbezogene Daten auch auf alternativen Wegen, beispielsweise
          telefonisch, an uns zu übermitteln.
        </div>

        <br />
        <br />
        <div className="sideTitleFull">1. Begriffsbestimmungen</div>
        <div>
          Die Datenschutzerklärung der Pflegedienst Premium beruht auf den
          Begrifflichkeiten, die durch den Europäischen Richtlinien- und
          Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DSGVO)
          verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die
          Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach
          lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir
          vorab die verwendeten Begrifflichkeiten erläutern.
          <br />
          Wir verwenden in dieser Datenschutzerklärung unter anderem die
          folgenden Begriffe:
        </div>

        <h3>1) Personenbezogene Daten</h3>
        <div>
          Personenbezogene Daten sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person (im Folgenden
          „betroffene Person") beziehen. Als identifizierbar wird eine
          natürliche Person angesehen, die direkt oder indirekt, insbesondere
          mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
          Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
          oder mehreren besonderen Merkmalen, die Ausdruck der physischen,
          physiologischen, genetischen, psychischen, wirtschaftlichen,
          kulturellen oder sozialen Identität dieser natürlichen Person sind,
          identifiziert werden kann.
        </div>

        <h3>2) Betroffene Person</h3>
        <div>
          Betroffene Person ist jede identifizierte oder identifizierbare
          natürliche Person, deren personenbezogene Daten von dem für die
          Verarbeitung Verantwortlichen verarbeitet werden.
        </div>

        <h3>3) Verarbeitung</h3>
        <div>
          Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren
          ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
          personenbezogenen Daten, wie das Erheben, das Erfassen, die
          Organisation, das Ordnen, die Speicherung, die Anpassung oder
          Veränderung, das Auslesen, das Abfragen, die Verwendung, die
          Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
          Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
          das Löschen oder die Vernichtung.
        </div>

        <h3>4) Einschränkung der Verarbeitung</h3>
        <div>
          Einschränkung der Verarbeitung ist die Markierung gespeicherter
          personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
          einzuschränken.
        </div>

        <h3>5) Profiling</h3>
        <div>
          Profiling ist jede Art der automatisierten Verarbeitung
          personenbezogener Daten, die darin besteht, dass diese
          personenbezogenen Daten verwendet werden, um bestimmte persönliche
          Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
          insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher
          Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit,
          Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person
          zu analysieren oder vorherzusagen.
        </div>

        <h3>6) Pseudonymisierung</h3>
        <div>
          Pseudonymisierung ist die Verarbeitung personenbezogener Daten in
          einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung
          zusätzlicher Informationen nicht mehr einer spezifischen betroffenen
          Person zugeordnet werden können, sofern diese zusätzlichen
          Informationen gesondert aufbewahrt werden und technischen und
          organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die
          personenbezogenen Daten nicht einer identifizierten oder
          identifizierbaren natürlichen Person zugewiesen werden.
        </div>

        <h3>7) Verantwortlicher oder für die Verarbeitung Verantwortlicher</h3>
        <div>
          Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die
          natürliche oder juristische Person, Behörde, Einrichtung oder andere
          Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind
          die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder
          das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche
          beziehungsweise können die bestimmten Kriterien seiner Benennung nach
          dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
        </div>

        <h3>8) Auftragsverarbeiter</h3>
        <div>
          Auftragsverarbeiter ist eine natürliche oder juristische Person,
          Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im
          Auftrag des Verantwortlichen verarbeitet.
        </div>

        <h3>9) Empfänger</h3>
        <div>
          Empfänger ist eine natürliche oder juristische Person, Behörde,
          Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt
          werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt
          oder nicht. Behörden, die im Rahmen eines bestimmten
          Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
          Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
          jedoch nicht als Empfänger.
        </div>

        <h3>10) Dritter</h3>
        <div>
          Dritter ist eine natürliche oder juristische Person, Behörde,
          Einrichtung oder andere Stelle außer der betroffenen Person, dem
          Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter
          der unmittelbaren Verantwortung des Verantwortlichen oder des
          Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
          verarbeiten.
        </div>

        <h3>11) Einwilligung</h3>
        <div>
          Einwilligung ist jede von der betroffenen Person freiwillig für den
          bestimmten Fall in informierter Weise und unmissverständlich
          abgegebene Willensbekundung in Form einer Erklärung oder einer
          sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
          Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
          betreffenden personenbezogenen Daten einverstanden ist.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          2. Name und Anschrift des für die Verarbeitung Verantwortlichen
        </div>
        <div>
          Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger
          in den Mitgliedstaaten der Europäischen Union geltenden
          Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem
          Charakter, ist die:
        </div>

        <h3>Herausgeber: Pflegedienst Premium</h3>
        <div>
          Anschrift: Moltkestraße 51, 74321 Bietigheim-Bissingen <br/>
          Tel.: 071422239345 <br />
          Fax: 071422239344 <br />
          E-Mail: info@premium-pflegedienst.de <br />
          Website: www.premium-pflegedienst.de <br />
        </div>

        <br />
        <br />
        <div className="sideTitleFull">3. Cookies</div>
        <div>
          Die Internetseiten der Pflegedienst Premium verwenden Cookies. Cookies
          sind Textdateien, welche über einen Internetbrowser auf einem
          Computersystem abgelegt und gespeichert werden. <br />
          Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies
          enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine
          eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge,
          durch welche Internetseiten und Server dem konkreten Internetbrowser
          zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies
          ermöglicht es den besuchten Internetseiten und Servern, den
          individuellen Browser der betroffenen Person von anderen
          Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein
          bestimmter Internetbrowser kann über die eindeutige Cookie-ID
          wiedererkannt und identifiziert werden. <br />
          Durch den Einsatz von Cookies kann die Pflegedienst Premium den
          Nutzern dieser Internetseite nutzerfreundlichere Services
          bereitstellen, die ohne die Cookie-Setzung nicht möglich wären. <br />
          Mittels eines Cookies können die Informationen und Angebote auf
          unserer Internetseite im Sinne des Benutzers optimiert werden. Cookies
          ermöglichen uns, wie bereits erwähnt, die Benutzer unserer
          Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es,
          den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der
          Benutzer einer Internetseite, die Cookies verwendet, muss
          beispielsweise nicht bei jedem Besuch der Internetseite erneut seine
          Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem
          Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein
          weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der
          Online-Shop merkt sich die Artikel, die ein Kunde in den virtuellen
          Warenkorb gelegt hat, über ein Cookie. <br />
          Beim Aufruf unserer Website wird der Nutzer über die Verwendung von
          Cookies zu Analysezwecken informiert und seine Einwilligung zur
          Verarbeitung der in diesem Zusammenhang verwendeten personenbezogenen
          Daten eingeholt. In diesem Zusammenhang erfolgt auch ein Hinweis auf
          diese Datenschutzerklärung. <br />
          Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
          Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1 lit. f
          DSGVO. <br />
          Die betroffene Person kann die Setzung von Cookies durch unsere
          Internetseite jederzeit mittels einer entsprechenden Einstellung des
          genutzten Internetbrowsers verhindern und damit der Setzung von
          Cookies dauerhaft widersprechen. Ferner können bereits gesetzte
          Cookies jederzeit über einen Internetbrowser oder andere
          Softwareprogramme gelöscht werden. Dies ist in allen gängigen
          Internetbrowsern möglich. Deaktiviert die betroffene Person die
          Setzung von Cookies in dem genutzten Internetbrowser, sind unter
          Umständen nicht alle Funktionen unserer Internetseite vollumfänglich
          nutzbar.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          4. Erfassung von allgemeinen Daten und Informationen
        </div>
        <div>
          Die Internetseite der Pflegedienst Premium erfasst mit jedem Aufruf
          der Internetseite durch eine betroffene Person oder ein
          automatisiertes System eine Reihe von allgemeinen Daten und
          Informationen. Diese allgemeinen Daten und Informationen werden in den
          Logfiles des Servers gespeichert. Erfasst werden können die (1)
          verwendeten Browsertypen und Versionen, (2) das vom zugreifenden
          System verwendete Betriebssystem, (3) die Internetseite, von welcher
          ein zugreifendes System auf unsere Internetseite gelangt (sogenannte
          Referrer), (4) die Unterwebseiten, welche über ein zugreifendes System
          auf unserer Internetseite angesteuert werden, (5) das Datum und die
          Uhrzeit eines Zugriffs auf die Internetseite, (6) eine
          Internet-Protokoll-Adresse (IP-Adresse), (7) der
          InternetService-Provider des zugreifenden Systems und (8) sonstige
          ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von
          Angriffen auf unsere informationstechnologischen Systeme dienen.{" "}
          <br />
          Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die
          Pflegedienst Premium keine Rückschlüsse auf die betroffene Person.
          Diese Informationen werden vielmehr benötigt, um (1) die Inhalte
          unserer Internetseite korrekt auszuliefern, (2) die Inhalte unserer
          Internetseite sowie die Werbung für diese zu optimieren, (3) die
          dauerhafte Funktionsfähigkeit unserer informationstechnologischen
          Systeme und der Technik unserer Internetseite zu gewährleisten sowie
          (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
          Strafverfolgung notwendigen Informationen bereitzustellen. Diese
          anonym erhobenen Daten und Informationen werden durch die Pflegedienst
          Premium daher einerseits statistisch und ferner mit dem Ziel
          ausgewertet, den Datenschutz und die Datensicherheit in unserem
          Unternehmen zu erhöhen, um auch ein optimales Schutzniveau für die von
          uns verarbeiteten personenbezogenen Daten sicherzustellen. Die
          anonymen Daten der Server-Logfiles werden getrennt von allen durch
          eine betroffene Person angegebenen personenbezogenen Daten
          gespeichert. <br />
          Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person
          zu machen. Zur Verbesserung unseres Online-Angebotes speichern wir
          jedoch (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu
          diesen Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei
          oder der Name Ihres InternetProviders. Durch die Anonymisierung der
          Daten sind Rückschlüsse auf Ihre Person nicht möglich.Nicht hiervon
          betroffen sind die IP-Adressen des Nutzers oder andere Daten, die die
          Zuordnung der Daten zu einem Nutzer ermöglichen. Eine Speicherung
          dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers
          findet nicht statt.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          5. Kontaktmöglichkeit über die Internetseite
        </div>
        <div>
          Die Internetseite der Pflegedienst Premium enthält aufgrund von
          gesetzlichen Vorschriften Angaben, die eine schnelle elektronische
          Kontaktaufnahme zu unserem Unternehmen sowie eine unmittelbare
          Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine
          Adresse der sogenannten elektronischen Post (E-MailAdresse) umfasst.
          Sofern eine betroffene Person per E-Mail oder über ein Kontaktformular
          den Kontakt mit dem für die Verarbeitung Verantwortlichen aufnimmt,
          werden die von der betroffenen Person übermittelten personenbezogenen
          Daten automatisch gespeichert. Solche auf freiwilliger Basis von einer
          betroffenen Person an den für die Verarbeitung Verantwortlichen
          übermittelten personenbezogenen Daten werden für Zwecke der
          Bearbeitung oder der Kontaktaufnahme zur betroffenen Person
          gespeichert. Es erfolgt keine Weitergabe dieser personenbezogenen
          Daten an Dritte.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          6. Kommentarfunktion im Blog auf der Internetseite
        </div>
        <div>
          Die Pflegedienst Premium bietet den Nutzern auf einem Blog, der sich
          auf der Internetseite des für die Verarbeitung Verantwortlichen
          befindet, die Möglichkeit, individuelle Kommentare zu einzelnen
          Blog-Beiträgen zu hinterlassen. Ein Blog ist ein auf einer
          Internetseite geführtes, in der Regel öffentlich einsehbares Portal,
          in welchem eine oder mehrere Personen, die Blogger oder Web-Blogger
          genannt werden, Artikel posten oder Gedanken in sogenannten Blogposts
          niederschreiben können. Die Blogposts können in der Regel von Dritten
          kommentiert werden. <br />
          Hinterlässt eine betroffene Person einen Kommentar in dem auf dieser
          Internetseite veröffentlichten Blog, werden neben den von der
          betroffenen Person hinterlassenen Kommentaren auch Angaben zum
          Zeitpunkt der Kommentareingabe sowie zu dem von der betroffenen Person
          gewählten Nutzernamen (Pseudonym) gespeichert und veröffentlicht.
          Ferner wird die vom Internet-Service-Provider (ISP) der betroffenen
          Person vergebene IP-Adresse mitprotokolliert. Diese Speicherung der
          IP-Adresse erfolgt aus Sicherheitsgründen und für den Fall, dass die
          betroffene Person durch einen abgegebenen Kommentar die Rechte Dritter
          verletzt oder rechtswidrige Inhalte postet. Die Speicherung dieser
          personenbezogenen Daten erfolgt daher im eigenen Interesse des für die
          Verarbeitung Verantwortlichen, damit sich dieser im Falle einer
          Rechtsverletzung gegebenenfalls exkulpieren könnte. Es erfolgt keine
          Weitergabe dieser erhobenen personenbezogenen Daten an Dritte, sofern
          eine solche Weitergabe nicht gesetzlich vorgeschrieben ist oder der
          Rechtsverteidigung des für die Verarbeitung Verantwortlichen dient.
        </div>

        <br />
        <br />
        <div className="sideTitleFull">
          7. Routinemäßige Löschung und Sperrung von personenbezogenen Daten
        </div>
        <div>
          Der für die Verarbeitung Verantwortliche verarbeitet und speichert
          personenbezogene Daten der betroffenen Person nur für den Zeitraum,
          der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern
          dies durch den europäischen Richtlinien- und Verordnungsgeber oder
          einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der
          für die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.{" "}
          <br />
          Entfällt der Speicherungszweck oder läuft eine vom europäischen
          Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
          Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
          personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen
          Vorschriften gesperrt oder gelöscht
        </div>

        <br />
        <br />

        <div className="sideTitleFull">8. Rechte der betroffenen Person</div>
        <h3>1) Recht auf Bestätigung</h3>
        <div>
          Jede betroffene Person hat das vom europäischen Richtlinien- und
          Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
          Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie
          betreffende personenbezogene Daten verarbeitet werden. Möchte eine
          betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann
          sie sich hierzu jederzeit an einen Mitarbeiter des für die
          Verarbeitung Verantwortlichen wenden.
        </div>

        <h3>2) Recht auf Auskunft</h3>
        <div>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, jederzeit von dem für die Verarbeitung Verantwortlichen
          unentgeltliche Auskunft über die zu seiner Person gespeicherten
          personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten.
          Ferner hat der Europäische Richtlinien- und Verordnungsgeber der
          betroffenen Person Auskunft über folgende Informationen zugestanden:
          <ul className="serviceListLeft">
            <li>die Verarbeitungszwecke</li>
            <li>
              die Kategorien personenbezogener Daten, die verarbeitet werden
            </li>
            <li>
              die Empfänger oder Kategorien von Empfängern, gegenüber denen die
              personenbezogenen Daten offengelegt worden sind oder noch
              offengelegt werden, insbesondere bei Empfängern in Drittländern
              oder bei internationalen Organisationen
            </li>
            <li>
              Falls möglich die geplante Dauer, für die die personenbezogenen
              Daten gespeichert werden, oder, falls dies nicht möglich ist, die
              Kriterien für die Festlegung dieser Dauer
            </li>
            <li>
              das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
              betreffenden personenbezogenen Daten oder auf Einschränkung der
              Verarbeitung durch den Verantwortlichen oder eines
              Widerspruchsrechts gegen diese Verarbeitung
            </li>
            <li>
              das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
            </li>
            <li>
              wenn die personenbezogenen Daten nicht bei der betroffenen Person
              erhoben werden: Alle verfügbaren Informationen über die Herkunft
              der Daten
            </li>
            <li>
              das Bestehen einer automatisierten Entscheidungsfindung
              einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO und —
              zumindest in diesen Fällen — aussagekräftige Informationen über
              die involvierte Logik sowie die Tragweite und die angestrebten
              Auswirkungen einer derartigen Verarbeitung für die betroffene
              Person
            </li>
          </ul>{" "}
          Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob
          personenbezogene Daten an ein Drittland oder an eine internationale
          Organisation übermittelt wurden. Sofern dies der Fall ist, so steht
          der betroffenen Person im Übrigen das Recht zu, Auskunft über die
          geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.
          Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch
          nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
          die Verarbeitung Verantwortlichen wenden.
        </div>

        <h3>3) Recht auf Berichtigung</h3>
        <div>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, die unverzügliche Berichtigung sie betreffender unrichtiger
          personenbezogener Daten zu verlangen. Ferner steht der betroffenen
          Person das Recht zu, unter Berücksichtigung der Zwecke der
          Verarbeitung, die Vervollständigung unvollständiger personenbezogener
          Daten — auch mittels einer ergänzenden Erklärung — zu verlangen.
          Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch
          nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
          die Verarbeitung Verantwortlichen wenden.
        </div>

        <h3>4) Recht auf Löschung (Recht auf Vergessen werden)</h3>
        <div>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, von dem Verantwortlichen zu verlangen, dass die sie
          betreffenden personenbezogenen Daten unverzüglich gelöscht werden,
          sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung
          nicht erforderlich ist:
          <ul className="serviceListLeft">
            <li>
              Die personenbezogenen Daten wurden für solche Zwecke erhoben oder
              auf sonstige Weise verarbeitet, für welche sie nicht mehr
              notwendig sind.
            </li>
            <li>
              Die betroffene Person widerruft ihre Einwilligung, auf die sich
              die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO oder Art. 9
              Abs. 2 Buchstabe a DSGVO stützte, und es fehlt an einer
              anderweitigen Rechtsgrundlage für die Verarbeitung.
            </li>
            <li>
              Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO Widerspruch
              gegen die Verarbeitung ein, und es liegen keine vorrangigen
              berechtigten Gründe für die Verarbeitung vor, oder die betroffene
              Person legt gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die
              Verarbeitung ein.
            </li>
            <li>
              Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
            </li>
            <li>
              Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
              rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
              Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
            </li>
            <li>
              Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste
              der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
            </li>
          </ul>
          Sofern einer der oben genannten Gründe zutrifft und eine betroffene
          Person die Löschung von personenbezogenen Daten, die bei der
          Pflegedienst Premium gespeichert sind, veranlassen möchte, kann sie
          sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
          Verantwortlichen wenden. Der Mitarbeiter der Pflegedienst Premium wird
          veranlassen, dass dem Löschverlangen unverzüglich nachgekommen
          wird.Wurden die personenbezogenen Daten von der Pflegedienst Premium
          öffentlich gemacht und ist unser Unternehmen als Verantwortlicher
          gemäß Art. 17 Abs. 1 DSGVO zur Löschung der personenbezogenen Daten
          verpflichtet, so trifft die Pflegedienst Premium unter
          Berücksichtigung der verfügbaren Technologie und der
          Implementierungskosten angemessene Maßnahmen, auch technischer Art, um
          andere für die Datenverarbeitung Verantwortliche, welche die
          veröffentlichten personenbezogenen Daten verarbeiten, darüber in
          Kenntnis zu setzen, dass die betroffene Person von diesen anderen für
          die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links
          zu diesen personenbezogenen Daten oder von Kopien oder Replikationen
          dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung
          nicht erforderlich ist. Der Mitarbeiter der Pflegedienst Premium wird
          im Einzelfall das Notwendige veranlassen.
        </div>

        <h3>5) Recht auf Einschränkung der Verarbeitung</h3>
        <div>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu
          verlangen, wenn eine der folgenden Voraussetzungen gegeben ist: <br />
          Die Richtigkeit der personenbezogenen Daten wird von der betroffenen
          Person bestritten, und zwar für eine Dauer, die es dem
          Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen
          Daten zu überprüfen. <br />
          Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die
          Löschung der personenbezogenen Daten ab und verlangt stattdessen die
          Einschränkung der Nutzung der personenbezogenen Daten. <br />
          Der Verantwortliche benötigt die personenbezogenen Daten für die
          Zwecke der Verarbeitung nicht länger, die betroffene Person benötigt
          sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen. <br />
          Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art.
          21 Abs. 1 DSGVO eingelegt und es steht noch nicht fest, ob die
          berechtigten Gründe des Verantwortlichen gegenüber denen der
          betroffenen Person überwiegen. <br />
          Sofern eine der oben genannten Voraussetzungen gegeben ist und eine
          betroffene Person die Einschränkung von personenbezogenen Daten, die
          bei der Pflegedienst Premium gespeichert sind, verlangen möchte, kann
          sie sich hierzu jederzeit an einen Mitarbeiter des für die
          Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Pflegedienst
          Premium wird die Einschränkung der Verarbeitung veranlassen.
        </div>

        <h3>6) Recht auf Datenübertragbarkeit</h3>
        <div>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, die sie betreffenden personenbezogenen Daten, welche durch die
          betroffene Person einem Verantwortlichen bereitgestellt wurden, in
          einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten. Sie hat außerdem das Recht, diese Daten einem anderen
          Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
          personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
          die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a
          DSGVO oder Art. 9 Abs. 2 Buchstabe a DSGVO oder auf einem Vertrag
          gemäß Art. 6 Abs. 1 Buchstabe b DSGVO beruht und die Verarbeitung
          mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung
          nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im
          öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
          erfolgt, welche dem Verantwortlichen übertragen wurde. Ferner hat die
          betroffene Person bei der Ausübung ihres Rechts auf
          Datenübertragbarkeit gemäß Art. 20 Abs. 1 DSGVO das Recht, zu
          erwirken, dass die personenbezogenen Daten direkt von einem
          Verantwortlichen an einen anderen Verantwortlichen übermittelt werden,
          soweit dies technisch machbar ist und sofern hiervon nicht die Rechte
          und Freiheiten anderer Personen beeinträchtigt werden. Zur
          Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die
          betroffene Person jederzeit an einen Mitarbeiter der Pflegedienst
          Premium wenden.
        </div>

        <h3>7) Recht auf Widerspruch</h3>
        <div>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
          jederzeit gegen die Verarbeitung sie betreffender personenbezogener
          Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DSGVO
          erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese
          Bestimmungen gestütztes Profiling. Die Pflegedienst Premium
          verarbeitet die personenbezogenen Daten im Falle des Widerspruchs
          nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für
          die Verarbeitung nachweisen, die den Interessen, Rechten und
          Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung
          dient der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen. Verarbeitet die Pflegedienst Premium
          personenbezogene Daten, um Direktwerbung zu betreiben, so hat die
          betroffene Person das Recht, jederzeit Widerspruch gegen die
          Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung
          einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher
          Direktwerbung in Verbindung steht. Widerspricht die betroffene Person
          gegenüber der Pflegedienst Premium der Verarbeitung für Zwecke der
          Direktwerbung, so wird die Pflegedienst Premium die personenbezogenen
          Daten nicht mehr für diese Zwecke verarbeiten. Zudem hat die
          betroffene Person das Recht, aus Gründen, die sich aus ihrer
          besonderen Situation ergeben, gegen die sie betreffende Verarbeitung
          personenbezogener Daten, die bei der Pflegedienst Premium zu
          wissenschaftlichen oder historischen Forschungszwecken oder zu
          statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgen, Widerspruch
          einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung
          einer im öffentlichen Interesse liegenden Aufgabe erforderlich. Zur
          Ausübung des Rechts auf Widerspruch kann sich die betroffene Person
          direkt jeden Mitarbeiter der Pflegedienst Premium oder einen anderen
          Mitarbeiter wenden. Der betroffenen Person steht es ferner frei, im
          Zusammenhang mit der Nutzung von Diensten der
          Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr
          Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
          denen technische Spezifikationen verwendet werden.
        </div>

        <h3>
          8) Automatisierte Entscheidungen im Einzelfall, einschließlich
          Profiling
        </h3>
        <div>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, nicht einer ausschließlich auf einer automatisierten
          Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
          unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet
          oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die
          Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines
          Vertrags zwischen der betroffenen Person und dem Verantwortlichen
          erforderlich ist, oder (2) aufgrund von Rechtsvorschriften der Union
          oder der Mitgliedstaaten, denen der Verantwortliche unterliegt,
          zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur
          Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen
          der betroffenen Person enthalten oder (3) mit ausdrücklicher
          Einwilligung der betroffenen Person erfolgt. Ist die Entscheidung (1)
          für den Abschluss oder die Erfüllung eines Vertrags zwischen der
          betroffenen Person und dem Verantwortlichen erforderlich oder (2)
          erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person,
          trifft die Pflegedienst Premium angemessene Maßnahmen, um die Rechte
          und Freiheiten sowie die berechtigten Interessen der betroffenen
          Person zu wahren, wozu mindestens das Recht auf Erwirkung des
          Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung
          des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
          Möchte die betroffene Person Rechte mit Bezug auf automatisierte
          Entscheidungen geltend machen, kann sie sich hierzu jederzeit an einen
          Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
        </div>

        <h3>9) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</h3>
        <div>
          Jede von der Verarbeitung personenbezogener Daten betroffene Person
          hat das vom europäischen Richtlinien- und Verordnungsgeber gewährte
          Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten
          jederzeit zu widerrufen. Möchte die betroffene Person ihr Recht auf
          Widerruf einer Einwilligung geltend machen, kann sie sich hierzu
          jederzeit an einen Mitarbeiter des für die Verarbeitung
          Verantwortlichen wenden.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          9. Verwendung von Bibliotheken (Webfonts)
        </div>
        <div>
          Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
          darzustellen, verwenden wir auf dieser Websitebibliotheken und
          Schriftbibliotheken wie z. B. Google Webfonts
          (https://www.google.com/webfonts/). Google Webfonts werden zur
          Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen.
          Falls der Browser die Google Webfonts nicht unterstützt oder den
          Zugriff unterbindet, werden Inhalte in einer Standardschrift
          angezeigt.
          <br />
          Der Aufruf vonbibliotheken oder Schriftbibliotheken löst automatisch
          eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es
          theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu
          welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten
          erheben. <br />
          Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie
          hier: https://www.google.com/policies/privacy/
        </div>

        <br />
        <br />

        <div className="sideTitleFull">10. Social Media Plug-ins</div>
        <div>
          Auf unseren Webseiten werden Social Plugins der unten aufgeführten
          Anbieter eingesetzt. Die Plugins können Sie daran erkennen, dass sie
          mit dem entsprechenden Logo gekennzeichnet sind. <br />
          Über diese Plugins werden unter Umständen Informationen, zu denen auch
          personenbezogene Daten gehören können, an den Dienstebetreiber
          gesendet und ggf. von diesem genutzt. Wir verhindern die unbewusste
          und ungewollte Erfassung und Übertragung von Daten an den
          Diensteanbieter durch eine 2-Klick-Lösung. Um ein gewünschtes Social
          Plugin zu aktivieren, muss dieses erst durch Klick auf den
          entsprechenden Schalter aktiviert werden. Erst durch diese Aktivierung
          des Plugins wird auch die Erfassung von Informationen und deren
          Übertragung an den Diensteanbieter ausgelöst. Wir erfassen selbst
          keine personenbezogenen Daten mittels der Social Plugins oder über
          deren Nutzung. <br />
          Wir haben keinen Einfluss darauf, welche Daten ein aktiviertes Plugin
          erfasst und wie diese durch den Anbieter verwendet werden. Derzeit
          muss davon ausgegangen werden, dass eine direkte Verbindung zu den
          Diensten des Anbieters ausgebaut wird sowie mindestens die IP-Adresse
          und gerätebezogene Informationen erfasst und genutzt werden. Ebenfalls
          besteht die Möglichkeit, dass die Diensteanbieter versuchen, Cookies
          auf dem verwendeten Rechner zu speichern. Welche konkreten Daten
          hierbei erfasst und wie diese genutzt werden, entnehmen Sie bitte den
          Datenschutzhinweisen des jeweiligen Diensteanbieters. Hinweis: Falls
          Sie zeitgleich bei Facebook angemeldet sind, kann Facebook Sie als
          Besucher einer bestimmten Seite identifizieren. <br />
          Wir haben auf unserer Website die Social-Media-Buttons folgender
          Unternehmen eingebunden: <br />
          Instagram https://www.instagram.com/premium_pflegedienst/ <br />
          Facebook <br />
          https://www.facebook.com/profile.php?id=100093074696170
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          11. Rechtsgrundlage der Verarbeitung
        </div>
        <div>
          Art. 6 I lit. a DSGVO dient unserem Unternehmen als Rechtsgrundlage
          für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen
          bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung
          personenbezogener Daten zur Erfüllung eines Vertrags, dessen
          Vertragspartei die betroffene Person ist, erforderlich, wie dies
          beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine
          Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder
          Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I
          lit. b DSGVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur
          Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in
          Fällen von Anfragen zur unseren Produkten oder Leistungen. Unterliegt
          unser Unternehmen einer rechtlichen Verpflichtung durch welche eine
          Verarbeitung von personenbezogenen Daten erforderlich wird, wie
          beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die
          Verarbeitung auf Art. 6 I lit. c DSGVO. In seltenen Fällen könnte die
          Verarbeitung von personenbezogenen Daten erforderlich werden, um
          lebenswichtige Interessen der betroffenen Person oder einer anderen
          natürlichen Person zu schützen. Dies wäre beispielsweise der Fall,
          wenn ein Besucher in unserem Betrieb verletzt werden würde und
          daraufhin sein Name, sein Alter, seine Krankenkassendaten oder
          sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus
          oder sonstige Dritte weitergegeben werden müssten. Dann würde die
          Verarbeitung auf Art. 6 I lit. d DSGVO beruhen. Letztlich könnten
          Verarbeitungsvorgänge auf Art. 6 I lit. f DSGVO beruhen. Auf dieser
          Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der
          vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung
          zur Wahrung eines berechtigten Interesses unseres Unternehmens oder
          eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und
          Grundfreiheiten des Betroffenen nicht überwiegen. Solche
          Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil
          sie durch den europäischen Gesetzgeber besonders erwähnt wurden. Er
          vertrat insoweit die Auffassung, dass ein berechtigtes Interesse
          anzunehmen sein könnte, wenn die betroffene Person ein Kunde des
          Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DSGVO).
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          12. Berechtigte Interessen an der Verarbeitung, die von dem
          Verantwortlichen oder einem Dritten verfolgt werden
        </div>
        <div>
          Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit.
          f DSGVO ist unser berechtigtes Interesse die Durchführung unserer
          Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter
          und unserer Anteilseigner.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          13. Dauer, für die die personenbezogenen Daten gespeichert werden
        </div>
        <div>
          Das Kriterium für die Dauer der Speicherung von personenbezogenen
          Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf
          der Frist werden die entsprechenden Daten routinemäßig gelöscht,
          sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung
          erforderlich sind.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          14. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
          personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss;
          Verpflichtung der betroffenen Person, die personenbezogenen Daten
          bereitzustellen; mögliche Folgen der Nichtbereitstellung
        </div>
        <div>
          Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener
          Daten zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften)
          oder sich auch aus vertraglichen Regelungen (z.B. Angaben zum
          Vertragspartner) ergeben kann. Mitunter kann es zu einem
          Vertragsschluss erforderlich sein, dass eine betroffene Person uns
          personenbezogene Daten zur Verfügung stellt, die in der Folge durch
          uns verarbeitet werden müssen. Die betroffene Person ist
          beispielsweise verpflichtet uns personenbezogene Daten
          bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag
          abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte
          zur Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen
          werden könnte. Vor einer Bereitstellung personenbezogener Daten durch
          den Betroffenen muss sich der Betroffene an einen unserer Mitarbeiter
          wenden. Unser Mitarbeiter klärt den Betroffenen einzelfallbezogen
          darüber auf, ob die Bereitstellung der personenbezogenen Daten
          gesetzlich oder vertraglich vorgeschrieben oder für den
          Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die
          personenbezogenen Daten bereitzustellen, und welche Folgen die
          Nichtbereitstellung der personenbezogenen Daten hätte.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          15. Änderung der Datenschutzbestimmung
        </div>
        <div>
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entspricht oder um
          Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
          z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
          dann die neue Datenschutzerklärung.
        </div>

        <br />
        <br />

        <div className="sideTitleFull">
          16. Bestehen einer automatisierten Entscheidungsfindung
        </div>
        <div>
          Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
          automatische Entscheidungsfindung oder ein Profiling
        </div>

        <br />
        <br />
      </Container>
    </div>
  );
};

export default Gdpr;
