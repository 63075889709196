import React from 'react';
import { NavLink } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div>© Copyright Senaida Murtic</div>
      <div>
        <NavLink className={"linkFooter"} to="/gdpr">Datenschutz</NavLink>
        <NavLink className={"linkFooter"} to="/imprint">Impressum</NavLink>
        <NavLink className={"linkFooter"} to="/contact">Kontakt</NavLink>
      </div>  
      <br/>    
    </footer>  
    );
};

export default Footer;