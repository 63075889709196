import React from "react";
import { NavLink } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Container from "../shared/UIelements/Container";

import carousel1 from "../images/carousel/carousel1.png";
import carousel2 from "../images/carousel/carousel2.png";
import carousel3 from "../images/carousel/carousel3.png";

const Overview = () => {
  return (
    <div>
      <Container title={"WAS UNS MOTIVIERT?"}>
        <div className="textContainer">
          Wir wollen mit unserem Pflegedienst Premium, dass die
          Menschen so lange wie möglich zuhause leben, sich wohlfühlen, sicher
          sind mit gewöhntem Komfort.
        </div>
        <br />
        <br />
        <div className="sideTitle">
          Das wünscht sich doch jeder,
          <br />
          oder?
        </div>
        <div className="sideText">
          Wir haben es uns zur Aufgabe gemacht Ihnen dies zu ermöglichen und
          versprechen unsere Premium Leistung. Wir unterstützen Sie oder Ihre
          Familie, damit Sie so lange wie möglich selbstständig durch eine
          häusliche Betreuung, in Ihrem gewohnten Umfeld bleiben können.
        </div>
        <br />
        <br />
        <Carousel>
          <div>
            <img src={carousel1} alt="Hende" className="imageCarosel"></img>
          </div>
          <div>
            <img src={carousel2} alt="Alte Frau" className="imageCarosel"></img>
          </div>
          <div>
            <img src={carousel3} alt="Blume" className="imageCarosel"></img>
          </div>
        </Carousel>
        <br/><br/>
        <div className="textContainer">Lernen Sie einfach unsere umfangreichen 
        <NavLink className={"linkLine"} to={'/service'}>
                {'Leistungen'}
              </NavLink>
          kennen, in einem unverbindlichen Gespräch.</div>
      </Container>
    </div>
  );
};

export default Overview;
