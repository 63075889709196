import React from "react";

import Container from "../shared/UIelements/Container";
import aboutus from "../images/boss.jpg";

import aboutUs1 from "../images/staff/staffAboutUs1.jpg";
import aboutUs2 from "../images/staff/staffAboutUs2.JPG";
import aboutUs3 from "../images/staff/staffAboutUs3.JPG";
import aboutUs4 from "../images/staff/staffAboutUs4.jpg";
import aboutUs5 from "../images/staff/staffAboutUs5.jpg";
import aboutUs6 from "../images/staff/staffAboutUs6.jpg";
import aboutUs7 from "../images/staff/staffAboutUs7.jpg";
import aboutUs8 from "../images/staff/staffAboutUs8.jpg";
import aboutUs9 from "../images/staff/staffAboutUs9.jpg";
import aboutUs10 from "../images/staff/staffAboutUs10.jpg";
import aboutUs11 from "../images/staff/staffAboutUs11.jpg";
import aboutUs12 from "../images/staff/staffAboutUs12.jpg";
import aboutUs13 from "../images/staff/staffAboutUs13.jpg";
import aboutUs14 from "../images/staff/staffAboutUs14.jpg";
import aboutUs15 from "../images/staff/staffAboutUs15.jpg";
import aboutUs16 from "../images/staff/staffAboutUs16.jpg";
import aboutUs17 from "../images/staff/staffAboutUs17.jpg";
import aboutUs18 from "../images/staff/staffAboutUs18.jpg";
import aboutUs19 from "../images/staff/staffAboutUs19.jpg";
import aboutUs20 from "../images/staff/staffAboutUs20.jpg";
import aboutUs21 from "../images/staff/staffAboutUs21.jpg";
import aboutUs22 from "../images/staff/staffAboutUs22.jpg";
import aboutUs23 from "../images/staff/staffAboutUs23.jpg";
import aboutUs24 from "../images/staff/staffAboutUs24.jpg";
import aboutUs25 from "../images/staff/staffAboutUs25.jpg";
import aboutUs26 from "../images/staff/staffAboutUs26.jpg";
import aboutUs27 from "../images/staff/staffAboutUs27.jpg";
import aboutUs28 from "../images/staff/staffAboutUs28.jpg";
import aboutUs29 from "../images/staff/staffAboutUs29.jpg";

const AboutUs = () => {
  return (
    <Container title={"Über uns"}>
      <div className="textContainer">
        Unser Pflegedienst Premium ist ein junges Unternehmen, welches mit viel
        Herz, Motivation und eigen Erwartungen im August 2023 gegründet wurde.
      </div>
      <br />
      <br />
      <div className="sideTitle">
        <img src={aboutus} alt="Boss" className="bossImage"></img>
      </div>
      <div className="sideText">
        Wir wussten schon immer, dass wir uns in der Pflege selbständig machen
        möchten, damit wir da was verändern können, nun ist es so weit.
        <br />
        Frau Murtic absolvierte erfolgreich ihre Ausbildung zur Altenpflegerin
        im Jahr 2019, im Jahr 2021 machte sie die Weiterbildung zur
        Praxisanleiterin und in demselben Jahr fing sie ihre Weiterbildung zur
        Pflegedienstleitung, welche sie im Jahr 2022 erfolgreich absolviert hat.
        <br />
        Mit ihrer Arbeit, die sie in der Stationären sowohl in der Ambulanten
        Pflege machte, konnte sie viele neue Erfahrungen sammeln, die sie jetzt
        in ihren Pflegedienst stecken kann.
        <br />
        Unser Pflegedienst ist nicht wie jeder andere Pflegedienst, denn unsere
        Betreuung ist mit Herz und Verstand, eher gesagt familiär.
        <br />
        Wir freuen uns über jeden Klienten, den wir in unserem Pflegedienst
        begrüßen dürfen, weil wir wollen Ihnen das Leben zu Hause so schön wie
        möglich machen. Die Dauer und Häufigkeit der Besuche unseres
        Pflegedienstes richtet sich ganz nach dem individuellen Betreuungsbedarf
        des Erkrankten.
        <br />
        Gerne können Sie sich auch mit uns zu einem unverbindlichen persönlichen
        Gespräch in Verbindung setzen, welches selbstverständlich kostenfrei und
        unverbindlich ist. Ihr Pflegedienst Premium
      </div>

      <br />
      <br />

      <div className="legendService">Unsere Fotogalerie</div>
      <div>
        <img src={aboutus} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs4} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs2} alt="Boss" className="gallaryImage"></img>

        <img src={aboutUs3} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs5} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs1} alt="Boss" className="gallaryImage"></img>

        <img src={aboutUs6} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs7} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs8} alt="Boss" className="gallaryImage"></img>

        <img src={aboutUs9} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs12} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs11} alt="Boss" className="gallaryImage"></img>

        <img src={aboutUs10} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs13} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs15} alt="Boss" className="gallaryImage"></img>

        <img src={aboutUs18} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs17} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs19} alt="Boss" className="gallaryImage"></img>
        
        <img src={aboutUs14} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs16} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs23} alt="Boss" className="gallaryImage"></img>

        <img src={aboutUs20} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs21} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs22} alt="Boss" className="gallaryImage"></img>

        <img src={aboutUs24} alt="Boss" className="gallaryImageLong"></img>
        <img src={aboutUs27} alt="Boss" className="gallaryImage"></img>
        <img src={aboutUs29} alt="Boss" className="gallaryImage"></img>
      </div>
    </Container>
  );
};

export default AboutUs;
