import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./Navigation.css";
import logo from '../../logoL.jpg';
import hamburger from '../../hamburger.png';

import MenuDropDown from "./MenuDropDown";
import NavItems from "./NavItems";

const Navigation = (props) => {

  const [isMenuOpend, setIsMenuOpend] = useState(false); 

  const handleMenuOpenClose = (e) => {
    if(isMenuOpend){
      setIsMenuOpend(false);
    } else {
      setIsMenuOpend(true);
    }
  }

  return (
    <div>
    <div id="navigation">
      <img src={logo} alt="Logo" className="navLogo"/>
      <div>
        <div className="navTitle">Pflegedienst Premium</div> <br/>
        {/* <div className="navCopiright"> by <label className="sMurticLabel">S. Murtic</label></div> */}
      </div>
      <ul className="nav-links">
        {NavItems.map((n) => {
          return (
            <li>
              <NavLink className={"menu_a"} to={n.linkpath}>
                {n.linktext}
              </NavLink>
            </li>
          );
        })}
      </ul>
      <img src={hamburger} alt="Menu" className="hamburgerMenu" onClick={handleMenuOpenClose}/>
    </div>
        <div>
          {isMenuOpend && ( <MenuDropDown change={handleMenuOpenClose}/>)}
        </div>
    </div>
  );
};

export default Navigation;
